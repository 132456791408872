@import "partials/_main";
/* IE10+ X button fix */

input[type="text"]::-ms-clear {
  display: none;
}

.home {
  /************** Homepage search css starts here *************/

  .covid-19 {
    padding: 10px 10px;
    text-align: left;
    background: #fff;
    a {
      color: #000;
    }
    .info-icon {
      display: inline-block;
      color: #007cc3;
      border-radius: 6px;
      border: 1px solid #007cc3;
      width: 18px;
      height: 18px;
      line-height: 18px;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
    }
    .covid-message {
      display: inline;
      font-size: 14px;
      @include respond-to(sm) {
        font-size: 16px;
      }
    }
  }

  /*hide search bar*/
  .search-wrapper {
    display: none !important;
  }
  /* The search state overtakes the home page */
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-top: 60px;
    background-color: #e8e8e8;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
      @include respond-to(md) {
        margin-left: 8% !important;
        margin-right: 8% !important;
      }
    }
  }
  .hideme {
    display: none !important;
  }
  #close > img {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    @include respond-to(sm) {
      width: 32px;
      height: 32px;
      top: 30px;
      right: 30px;
    }
  }
  .search-form {
    margin-bottom: -5px;
    @include respond-to(sm) {
      margin-bottom: 0;
    }
    h4 {
      font-size: 15px !important;
      @include respond-to(sm) {
        font-size: 18px !important;
      }
    }
    input[type="text"] {
      font-size: 16px !important;
    }
    #search {
      margin-top: 10px;
      /*remove blue outline*/
      &:focus {
        outline: none !important;
        box-shadow: none;
        border: 1px solid #ccc !important;
      }
      @include respond-to(sm) {
        margin-top: 20px;
      }
    }
    .search-icon {
      top: 10px !important;
      left: 8px !important;
      width: 12px !important;
      height: 12px !important;
      @include respond-to(sm) {
        top: 15px !important;
        left: 15px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }
    /* Overwrite the original placement of the search icon */
    .search-icon1 {
      top: 35px !important;
      @include respond-to(sm) {
        top: 53px !important;
        left: 19px !important;
      }
    }
    .clear-form {
      top: 10px !important;
      right: 12px !important;
      width: 12px !important;
      height: 12px !important;
      @include respond-to(sm) {
        top: 15px !important;
        right: 19px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }
    /* Overwrite the original placement of the clear form icon */
    .clear-form1 {
      top: 35px !important;
      @include respond-to(sm) {
        top: 52px !important;
      }
    }
    .search-box {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center;
      @include respond-to(sm) {
        padding-left: 0 !important;
        text-align: center;
        font-size: $font-size-form;
        height: 45px;
      }
    }
    /*.search-box1 {
			@include respond-to(sm) {
				padding-left: 45px !important;
			}
		}*/
  } // End search wrapper
  /* Displays the result of search */
  .results-wrapper {
    margin-top: 30px !important;
    @include respond-to(sm) {
      margin-top: 60px !important;
    }
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    h4 {
      color: #909191;
      margin-bottom: 10px;
    }
    .result-type {
      img {
        width: 80%;
        margin: 0 auto;
      }
    }
    .result-type,
    .result-vehicle {
      .type {
        overflow: auto;
        padding: 12px 0;
        border-bottom: 2px solid #dcdcdc;
        cursor: pointer;
        @include respond-to(sm) {
          padding: 20px 0;
        }
        .vehicle,
        .copy,
        .total {
          display: inline-block;
          *display: inline;
          vertical-align: middle;
        }
        .vehicle {
          width: 33.33%;
        }
        .copy {
          width: 48%;
          padding-left: 10px;
          @include respond-to(sm) {
            width: 50%;
            padding-left: 30px;
          }
          h3 {
            font-family: $avenir-next-rounded-regular !important;
            @include respond-to(sm) {
              font-size: 24px !important;
            }
          }
        }
        .total {
          width: 15%;
          text-align: right;
        }
      }
    }
    .vehicles-header {
      margin-top: 20px;
      @include respond-to(sm) {
        margin-top: 40px;
      }
    }
    .remove-margin-top {
      margin-top: 0 !important;
    }
    div.result-type:last-child .type {
      border-bottom: none;
    }
    div.result-vehicle:last-child .type {
      border-bottom: none;
    }
    div.result-type:hover,
    div.result-vehicle:hover {
      background: #fff;
    }
  } // End results wrapper
  /* Search yielded no result */
  .no-result-wrapper {
    h4 {
      margin-top: 0;
      line-height: 20px;
      @include respond-to(sm) {
        margin-top: 70px;
        line-height: 22px;
      }
    }
    hr {
      width: 95%;
      height: 2px;
      background: #dcdcdc;
      border-top: none !important;
      @include respond-to(sm) {
        width: 620px;
        margin-top: 53px !important;
        margin-bottom: 23px !important;
      }
      @include respond-to(md) {
        width: 700px;
      }
    }
    .top4 {
      cursor: pointer;
    }
    .view-all-wrapper {
      overflow: auto;
      clear: both;
      h4 {
        margin-top: 15px !important;
        @include respond-to(sm) {
          margin-top: 30px !important;
        }
      }
      .btn {
        margin-top: 15px;
        margin-bottom: 30px;
        @include respond-to(sm) {
          margin-top: 30px;
        }
      }
    }
  }
  .no-result-vehicle-model {
    > div {
      padding: 0 !important;
    }
    img {
      display: inline;
    }
    @include respond-to(sm) {
      margin: 0 8%;
    }
    @include respond-to(md) {
    }
    p.model {
      font-size: $font-size-m-copy;
      margin-top: 8px;
      padding: 0 15px;
      @include respond-to(sm) {
        font-size: $font-size-copy;
        margin-top: 12px;
      }
      @include respond-to(md) {
        font-size: 20px;
      }
    }
    p.description {
      font-size: 10px;
      margin-top: 2px;
      margin-bottom: 20px;
      padding: 0 15px;
      @include respond-to(sm) {
        font-size: $font-size-h6;
        margin-bottom: 26px;
      }
    }
  } // End no results wrapper
  /************** Homepage search css ends here *************/
  /* Homepage Offer Promo */
  .promo {
    cursor: pointer;
    padding-bottom: 165%;
    position: relative;
    height: 0;
    overflow: hidden;
    @include respond-to(sm) {
      // padding-bottom: 69.66%;
    }
    @include respond-to(md) {
      padding-bottom: 56.25%;
    }
    .offer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(-/media/HondaCertified/Home/gone_ddse_webpromo_homepage_640x960.ashx);
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      -o-background-size: cover;
      // @include respond-to(sm) {
      //   background-image: url(-/media/HondaCertified/Home/gone_ddse_webpromo_homepage_768x1177.ashx);
      //   -webkit-background-size: cover;
      //   -moz-background-size: cover;
      //   background-size: cover;
      //   -o-background-size: cover;
      // }
      @include respond-to(md) {
        background-image: url(-/media/HondaCertified/Home/gone_ddse_webpromo_homepage_1440x762.ashx);
        max-width: 1440px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
      }
      @include respond-to(lg) {
        background-image: url(-/media/HondaCertified/Home/gone_ddse_webpromo_homepage_1440x762.ashx);
        max-width: 1440px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
      }
    }
    .home-promotion-iframe-disclaimer {
      // background: blue;
      // opacity: 0.4;
      position: absolute;
      width: 6%;
      height: 6%;
      left: 66.3%;
      top: 32.1%;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.001);
      @include respond-to(sm) {
        width: 7.5%;
    height: 5%;
    left: 66.8%;
    top: 33.2%;
      }
      @include respond-to(md) {
        width: 4%;
        height: 6%;
        left: 69.6%;
        top: 6.2%;
      }
      @include respond-to(lg) {
        width: 4%;
    height: 5%;
    left: 69.6%;
    top: 7.2%;
      }
    }
    .home-promotion-iframe-disclaimer-second {
      // background: red;
      // opacity: 0.4;
      position: absolute;
      width: 9%;
      height: 6%;
      left: 59.3%;
      top: 55.1%;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.001);
      @include respond-to(sm) {
        width: 7.5%;
        height: 5%;
        left: 60.8%;
        top: 55.2%;
      }
      @include respond-to(md) {
        width: 4%;
        height: 6%;
        left: 30.6%;
        top: 10.2%;
      }
      @include respond-to(lg) {
        width: 4%;
        height: 5%;
        left: 29.6%;
        top: 10.2%;
      }
    }
    .disclaimer-callout {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  /* Homepage Pano */
  .pano {
    img {
      width: 100%;
    }
    // background-image: url(/images/home/pano-mobile.jpg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // position: relative;
    // color: #fff;
    // height: 460px;
    // @include respond-to(sm) {
    //   background-image: url(/images/home/pano-tablet.jpg);
    //   height: 599px;
    // }
    // @include respond-to(md) {
    //   background-image: url(/images/home/pano-desktop.jpg);
    //   height: 760px;
    // }
    #copy {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 212px;
      @include respond-to(sm) {
        height: 166px;
      }
      h2 {
        line-height: 30px;
        @include respond-to(sm) {
          line-height: 40px;
        }
        @include respond-to(md) {
          line-height: 42px;
        }
      }
      hr {
        width: 45px;
        height: 2px;
        background: #898d8f;
        border-top: none !important;
        @include respond-to(sm) {
          width: 82px;
        }
      }
      .btn {
        padding: 4px 20px !important;
        display: block;
        margin: 0 auto;
        @include respond-to(sm) {
          padding: 8px 38px !important;
          display: inline-block;
        }
      }
      .btn-primary {
        height: 30px !important;
        @include respond-to(sm) {
          height: 40px !important;
        }
      }
      .btn-view-vehicles {
        margin-bottom: 20px;
        @include respond-to(sm) {
          margin-bottom: 0;
        }
      }
      .btn-see-offers {
        @include respond-to(sm) {
          margin-left: 50px;
        }
      }
    }
    a.explore {
      font-size: $font-size-m-copy;
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      text-transform: uppercase;
      @include respond-to(sm) {
        font-size: $font-size-copy;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
      }
      @include respond-to(md) {
        font-size: 14px;
        text-transform: none;
      }
    }
    a.explore > img {
      display: inline;
      width: 15px;
      margin-top: 6px;
      @include respond-to(sm) {
        width: auto;
        margin-top: 8px;
        margin-right: 4px;
      }
    }
  } // End homepage pano
  /* Homepage Video Pano */
  .video-pano {
    // display: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .overlay:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
    #video {
      object-fit: cover;
      /*width: 100% !important;
																													height: auto !important;*/
      z-index: -1;
      @include respond-to(sm) {
        width: 100% !important;
        height: auto !important;
      }
    }
    #copy {
      margin: auto;
      position: absolute;
      top: 87%;
      left: 0;
      bottom: 0;
      right: 0;
      height: 43px;
      color: #fff;
      @include respond-to("mobile") {
        display: none;
      }
      h2 {
        line-height: 30px;
        @include respond-to(sm) {
          line-height: 40px;
        }
        @include respond-to(md) {
          line-height: 42px;
        }
      }
      hr {
        width: 45px;
        height: 2px;
        background: #898d8f;
        border-top: none !important;
        @include respond-to(sm) {
          width: 82px;
        }
      }
      .btn {
        padding: 4px 20px !important;
        display: block;
        margin: 0 auto;
        @include respond-to(sm) {
          padding: 8px 38px !important;
          display: inline-block;
        }
      }
      .btn-primary {
        // background: transparent;
        // border: #fff solid 2px;
        height: 30px !important;
        @include respond-to(sm) {
          height: 40px !important;
        }
      }
      .btn-view-vehicles {
        margin-bottom: 20px;
        @include respond-to(sm) {
          margin-bottom: 0;
        }
      }
      .btn-see-offers {
        @include respond-to(sm) {
          margin-left: 50px;
        }
      }
    }
    a.explore {
      font-size: $font-size-m-copy;
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      text-transform: uppercase;
      @include respond-to(sm) {
        font-size: $font-size-copy;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
      }
      @include respond-to(md) {
        font-size: 14px;
        text-transform: none;
      }
    }
    a.explore > img {
      display: inline;
      width: 15px;
      margin-top: 6px;
      @include respond-to(sm) {
        width: auto;
        margin-top: 8px;
        margin-right: 4px;
      }
    }
  } // End Video Pano
  // Home animation ifram promo
  .home-promotion-iframe-wrapper {
    padding-bottom: 165%;
    position: relative;
    height: 0;
    overflow: hidden;
    @include respond-to(sm) {
      padding-bottom: 69%;
    }
    @include respond-to(md) {
      padding-bottom: 54.9%;
    }
    &:focus-within {
      outline:2px solid #000;
    }
    .home-promotion-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img{
      width: 100%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
    }
    .home-promotion-iframe-disclaimer {
      // display: none !important;
      position: absolute;
      width: 12%;
      height: 6%;
      left: 65.3%;
      top: 32.1%;
      z-index: 10;
      // background-color: rgba(0, 0, 0, 0.001);
      // background: yellow;
      opacity: 0.5;
      @include respond-to(sm) {
        width: 6.5%;
        height: 8%;
        left: 73.8%;
        top: 5.2%;
      }
      @include respond-to(md) {
        width: 7%;
        height: 7%;
        left: 68.6%;
        top: 6.2%;
      }
    }
    .disclaimer-callout {
      display: block;
      width: 100%;
      height: 100%;
    }
  } // END Home animation iframe promo

  .filter-drop {
    background-color: #007cc3;
    padding: 20px;

    h2 {
      @include respond-to("mobile") {
        display: block !important;
        color: white;
        margin-bottom: 15px;
      }
    }

    .filters {
      width: 58%;
      margin: auto;

      @include respond-to("tablet-") {
        width: 100%;
      }

      @media only screen and (min-width: 992px) and (max-width: 1050px) {
        width: 64%;
      }

      .space {
        padding-right: 0px;

        @include respond-to("tablet-") {
          margin-bottom: 15px;
        }
      }

      .filter-nav-sortby-btn-programs,
      .filter-nav-sortby-btn-vehicles {
        text-transform: uppercase;
        cursor: pointer;
        width: 100%;
        border-radius: 40px;
        background-color: #fff;
        border: 1px solid #ececec;
        text-align: left;
        padding: 10px 13px;
        z-index: 6;
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);

        &:focus {
          outline-offset: 4px;
          outline: 2px solid #fff !important;
        }

        img {
          position: absolute;
          left: calc(100% - 35px);
          top: 14px;
        }
      }

      .filter-nav-sortby-inner-programs,
      .filter-nav-sortby-inner-vehicles {
        position: absolute;
        text-transform: uppercase;
        top: 1px;
        left: 15px;
        width: calc(100% - 15px);
        border-radius: 15px;
        background-color: #fff;
        border: 1px solid #ececec;
        z-index: 6;
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
        display: none;
        padding-top: 4px;
      }

      .fnsb-item-programs,
      .fnsb-item-vehicles {
        border-bottom: 1px solid #ececec;
        text-align: left;
        background-repeat: no-repeat;
        background-position: 150px center;
        cursor: pointer;
        padding: 10px;
        text-transform: capitalize;
        // border-radius: 12px;

        img {
          top: 11px;
          position: absolute;
          left: calc(100% - 33px);
        }
        &:first-child {
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 13px;
          border-bottom-left-radius: 13px;
          text-transform: none;
        }
      }

      .fnsb-item-programs:last-of-type,
      .fnsb-item-vehicles:last-of-type {
        border-bottom: 0px solid #ececec;
      }

      .fnsb-item-programs:hover,
      .fnsb-item-vehicles:hover {
        background-color: rgb(180, 180, 180);
      }

      .fnsb-item-programs:focus,
      .fnsb-item-vehicles:focus {
        background-color: rgb(180, 180, 180);
      }

      .search-button {
        color: #fff;
        background-color: #0074B3;
        border: 2px solid #fff;
        width: 100%;
        height: 38px;
        transition: background-color 0.7s;
        outline: .2rem solid transparent;
        outline-offset: .2rem;
      }

      .search-button:hover {
        background-color: #fff;
        color: #0074B3;
        transition: background-color 0.7s;
      }

      .search-button:focus {
        background-color: #fff;
        color: #0074B3;
        transition: background-color 0.7s;
        outline: .2rem solid #fff !important;
        outline-offset: .2rem !important;
      }
    }
  }

  .benefits-module-certified,
  .benefits-module-used {
    padding: 40px 20px 50px;
    margin-bottom: 10px;
    border-radius: 10px;

    &[data-value="certified"] {
      background-color: #fff;
    }

    &[data-value="used"] {
      background-color: rgb(223, 223, 223);
    }

    &[data-value="hondatrue"] {
      background: #093f75;
      color: white;
    }

    @include respond-to("mobile") {
      border-radius: 0;
      padding: 30px 0 30px 0;
    }

    .flex-content-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      .benefit-card {
        flex: 1;

        @include respond-to("mobile") {
          border-right: 0;
        }
      }

      .benefit-card:nth-child(even) {
        @include respond-to("tablet") {
          border-right: 0;
        }
      }

      .benefit-card:last-child {
        @include respond-to("mobile") {
          margin-bottom: -40px;
        }
        @include respond-to("tablet") {
          margin-bottom: -40px;
        }
      }

      .flex-card {
        flex: 100%;

        @include respond-to("tablet") {
          flex: 50%;
        }
      }

      .benefit-card:last-child {
        border-right: 0;
      }
    }

    .visible-md-br {
      display: none;

      @include respond-to("tablet") {
        display: block;
      }
    }

    .hr-center-black {
      background-color: black;
      width: 75.5px;
      margin: 10px auto;
      height: 2px;
    }

    .hr-center-white {
      background-color: white;
      width: 75.5px;
      margin: 10px auto;
      height: 2px;
    }

    .benefit-card {
      padding: 13px;
      text-align: center;
      line-height: 22px;
      font-size: 16px;
      font-weight: 900;
      min-height: 198px;
      border-right: 1px solid rgba(0, 124, 195, 0.5);
      font-family: $avenir-next-demi;

      @media only screen and (max-width: 321px) {
        &[data-value="mobile-view-honda"],
        &[data-value="mobile-view-call"] {
          width: 49%;
        }

        &[data-value="mobile-view-full"] {
          width: 100%;
        }

        width: 47%;
      }

      @include respond-to("tablet-") {
        height: unset;
        padding: 13px 0 13px 0 !important;

        &[data-value="mobile-view-full"] {
          margin-top: 20px;
        }
      }

      .img-card {
        margin-bottom: 20px;
      }

      .img-card-width {
        width: 122px;
      }

      .text-benefit-card-width {
        width: 204px !important;

        @include respond-to("tablet-") {
          width: 250px !important;
        }
      }

      .text-benefit-card {
        margin: auto;
        width: 130px;
      }

      .text-benefit-card-call {
        width: 190px !important;

        @include respond-to("tablet-") {
          width: 155px !important;
        }
      }

      .text-benefit-card-honda {
        width: 190px !important;

        @include respond-to("tablet-") {
          width: 140px !important;
        }
      }

      .hr-border-inline {
        visibility: hidden;
        border-top: 1px solid rgba(0, 124, 195, 0.5);
        margin-top: unset !important;

        @include respond-to("tablet-") {
          visibility: visible !important;
          height: 0.02em;
          position: relative;
          top: 58px;
          width: 85%;
        }

        @media only screen and (max-width: 321px) {
          &[data-value="hr-shield"] {
            left: 4px;
          }
        }
      }

      .hr-position-top {
        @supports (-moz-appearance: none) {
          top: 35px !important;
        }
      }

      .hr-border-with {
        top: 14px;
      }

      .text-mobile-size {
        @include respond-to("mobile") {
          width: 155px !important;
          height: auto !important;
          margin-bottom: 15px;
        }

        @include respond-to("tablet-") {
          width: 155px !important;
          height: 198px;
        }
      }

      .text-benefit-card-width {
        margin: auto;
        width: 204px;
      }
    }

    .benefit-card-no-border {
      border: 0 !important;
    }

    .benefit-card-no-border-width {
      @include respond-to("tablet-") {
        border: 0 !important;
      }
    }

    .benefit-margin-top {
      @supports (-moz-appearance: none) {
        margin-top: 20px;
      }
    }

    .title-certified {
      @include respond-to("mobile") {
        width: 220px;
        margin: auto;
      }
    }

    .text-certified {
      margin: auto;
      width: 700px;
      margin-bottom: 30px;
      margin-top: 15px;
      line-height: 20px;

      @include respond-to("mobile") {
        width: 295px;
      }
    }

    .see-benefits {
      color: white;
      border-color: white;
      transition: background-color 0.7s;
      outline: .2rem solid transparent;
      outline-offset: .2rem;
    }

    .see-benefits:hover {
      background-color: #fff;
      color: #007cc3;
      transition: background-color 0.7s;
    }

    .see-benefits:focus {
      background-color: #fff;
      color: #007cc3;
      transition: background-color 0.7s;
      outline: .2rem solid #fff !important;
      outline-offset: .2rem !important;
    }

    .see-benefits-blue {
      background-color: #0074B3;
      color: white;
      outline: .2rem solid transparent;
      outline-offset: .2rem;

      &:hover {
        background-color: #fff;
        color: #0074B3;
        transition: background-color 0.7s;
      }

      &:focus {
        background-color: #fff;
        color: #0074B3;
        transition: background-color 0.7s;
        outline: .2rem solid #000 !important;
        outline-offset: .2rem !important;
      }

    }

    // .see-benefits-blue:hover {
    //   background-color: #fff;
    //   color: #007cc3;
    //   transition: background-color 0.7s;
    // }

    // .see-benefits-blue:focus {
    //   background-color: #fff;
    //   color: #007cc3;
    //   transition: background-color 0.7s;
    //   outline: .2rem solid #000 !important;
    //   outline-offset: .2rem !important;
    // }

    // Used benefit module
    .title-used {
      @include respond-to("mobile") {
        width: 300px;
        margin: auto;
      }
    }

    .text-used {
      margin: auto;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 15px;

      @include respond-to("mobile") {
        width: 310px;
      }
    }

    .hr-border-with {
      &[data-value="hr-honda"] {
        top: 40px;
      }

      &[data-value="hr-call"] {
        top: 53px;
      }

      @media only screen and (max-width: 321px) {
        left: 7px;

        &[data-value="hr-honda"] {
          left: 4px;
        }

        &[data-value="hr-call"] {
          left: 1px;
        }
      }
    }

    .btn {
      @include respond-to (sm) {
        padding: 5px 12px;
      }
    }
  }

  .benefits-module-used {
    .benefit-card {
      .disclaimer-callout {
        color:#fff;
      }
    }
  }
  .hsp-promo-banner {
    width: 100%;
    height: 295px;
    background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Desktop.jpg');
    background-size: cover;
    background-position: right;

    @include respond-to("desktopsm") {
      background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Tablet.png');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("tablet") {
      background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Tablet.png');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("mobile") {
      background: none;
      background-color: white;
      height: auto;
      margin-bottom: 20px;
    }

    img {
      display: none;

      @include respond-to("mobile") {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }

    div {
      @include respond-to("mobile") {
        padding-bottom: 20px;
        margin-top: -40px;
      }

      p {
        font-size: 36px;
        line-height: 45px;
        text-align: left;
        padding-top: 40px;
        padding-left: 50px;
        width: 50%;

        @include respond-to("desktopsm") {
          font-size: 30px;
          line-height: 33px;
        }

        @include respond-to("tablet") {
          font-size: 24px;
          line-height: 32px;
          width: 40%;
        }

        @include respond-to("mobile") {
          font-size: 26px;
          line-height: 29px;
          width: 100%;
          text-align: center;
          margin: 0;
          padding: 0 20px;
        }
      }

      a.see-benefits-blue {
        float: left;
        margin-top: 25px;
        background-color: #0074B3;
        color: white;
        margin-left: 50px;

        &:hover {
          background-color: #fff;
          color: #0074B3;
          transition: background-color 0.7s;
        }

        &:focus {
          background-color: #fff;
          color: #0074B3;
          transition: background-color 0.7s;
          outline: .2rem solid #000 !important;
          outline-offset: .2rem !important;
        }

        @include respond-to("mobile") {
          float: none;
          margin: 0;
          margin-top: 25px;
        }
      }

      a.disclaimer-callout.large-disclaimer {
        font-size: 36px;
        line-height: 45px;

        @include respond-to("desktopsm") {
          font-size: 30px;
          line-height: 33px;
        }

        @include respond-to("tablet") {
          font-size: 24px;
          line-height: 32px;
        }

        @include respond-to("mobile") {
          font-size: 26px;
          line-height: 29px;
        }
      }
    }
  }

  .leasing-promo-banner {
    width: 100%;
    height: 295px;
    background: white no-repeat url('/images/program-benefits/honda-certified-leasing_desktop.jpg');
    background-size: cover;
    background-position: right;
    color: white;

    @include respond-to("desktopsm") {
      background: white no-repeat url('/images/program-benefits/honda-certified-leasing_tablet.jpg');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("tablet") {
      background: white no-repeat url('/images/program-benefits/honda-certified-leasing_tablet.jpg');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("mobile") {
      background: none;
      background-color: black;
      height: auto;
      margin-bottom: 20px;
    }

    img {
      display: none;

      @include respond-to("mobile") {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }

    div {
      @include respond-to("mobile") {
        padding-bottom: 20px;
        margin-top: 20px;
      }

      p {
        font-size: 36px;
        line-height: 45px;
        text-align: left;
        padding-top: 40px;
        padding-left: 50px;
        width: 50%;

        @include respond-to("desktopsm") {
          font-size: 30px;
          line-height: 33px;
        }

        @include respond-to("tablet") {
          font-size: 24px;
          line-height: 32px;
          width: 40%;
        }

        @include respond-to("mobile") {
          font-size: 26px;
          line-height: 29px;
          width: 100%;
          text-align: center;
          margin: 0;
          padding: 0 20px;
        }
      }

      a.see-benefits-blue {
        float: left;
        margin-top: 25px;
        background-color: #0074B3;
        color: white;
        margin-left: 50px;

        &:hover {
          background-color: #fff;
          color: #0074B3;
          transition: background-color 0.7s;
        }

        &:focus {
          background-color: #fff;
          color: #0074B3;
          transition: background-color 0.7s;
          outline: .2rem solid #000 !important;
          outline-offset: .2rem !important;
        }

        @include respond-to("mobile") {
          float: none;
          margin: 0;
          margin-top: 25px;
        }
      }

      a.disclaimer-callout.large-disclaimer {
        font-size: 36px;
        line-height: 45px;

        @include respond-to("desktopsm") {
          font-size: 30px;
          line-height: 33px;
        }

        @include respond-to("tablet") {
          font-size: 24px;
          line-height: 32px;
        }

        @include respond-to("mobile") {
          font-size: 26px;
          line-height: 29px;
        }
      }
    }
  }

  .passing-torch {
    padding: 40px 0px 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: rgb(0, 124, 195);
    color: #fff;

    @include respond-to("mobile") {
      border-radius: 0px;
    }

    .hr-center-torch {
      background-color: #fff;
      width: 75.5px;
      margin: 20px auto;
      position: relative;
      bottom: 20px;

      @include respond-to("mobile") {
        position: static;
      }
    }

    .text-torch {
      font-size: 15px;
      margin-bottom: 20px;

      @include respond-to("mobile") {
        width: 290px;
        margin: auto;
      }
    }

    .see-torch {
      color: #fff;
      background-color: rgb(0, 124, 195);
      border: 2px solid #fff;
      transition: background-color 0.7s;
    }

    .see-torch:hover {
      background-color: #fff;
      color: #007cc3;
      transition: background-color 0.7s;
    }

    .container-title {
      .title-torch {
        display: inline-block;
        font-size: 35px;
        position: relative;
        bottom: 37px;

        @include respond-to("mobile") {
          display: block;
          top: 3px;
          margin-top: 10px;
        }
      }

      .img-torch {
        display: inline-block;
        position: relative;
        right: 40px;

        @include respond-to("mobile") {
          position: static;
        }
      }
    }

    .container-story {
      padding: 20px 15px 20px 15px;
      height: 500px;

      @include respond-to("tablet") {
        height: 570px;
      }

      @include respond-to("mobile") {
        padding: 20px 22px 0px 41px;
        height: auto;
        margin-bottom: 20px;
      }

      .btn-video {
        cursor: pointer;
      }

      .title {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 15px;
        font-size: 18px;
      }

      .text {
        text-align: left;
        font-size: 15px;
        width: 90%;
        line-height: 20px;

        &[data-value="0"] {
          width: 100%;
        }
      }

      .hr-div {
        height: 94%;
        background-color: #fff;
        position: absolute;
        right: 0px;
        width: 1.3px;
        top: 3.4%;
      }

      img {
        @include respond-to("mobile") {
          width: calc(100% - 19px);
        }
      }
    }

    .no-visible-xs {
      @include respond-to("mobile") {
        display: none;
      }
    }

    .visible-xs {
      display: none;

      @include respond-to("mobile") {
        display: block;
      }
    }

    .video-overlay {
      z-index: 100;

      .video-player-background {
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 100;

        .video-player {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          left: 0;
          right: 0;
          max-height: 449px;

          @include respond-to(sm) {
            max-height: 418px;
            max-width: 75%;
          }

          @include respond-to(md) {
            max-height: 650px;
          }

          @include respond-to(xl) {
            max-height: 660px;
            max-width: 1080px;
          }

          .video-wrapper {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;
            clear: both;

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        .video-close {
          background-image: url(/images/program-benefits/icons/close-icon.png);
          background-repeat: no-repeat;
          background-size: 12.5px;
          width: 12.5px;
          height: 12.5px;
          float: right;
          right: 0px;
          position: relative;
          cursor: pointer;
          margin: 0 15px 15px 0;
          -webkit-transition: ease 0.25s all;
          transition: ease 0.25s all;

          &:hover {
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Safari */
            transform: rotate(180deg);
          }

          @include respond-to(sm) {
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }

  .vehicle-or-type {
    margin-top: 28px;
    margin-bottom: 28px;
    @include respond-to(sm) {
      margin-top: 60px;
      margin-bottom: 32px;
    }
    .vehicles-cta,
    .type-cta {
      margin-right: 15px;
      display: inline;
      font-size: $font-size-m-copy;
      cursor: pointer;
      @include respond-to(sm) {
        font-size: 24px;
      }
    }
    .selected {
      color: #0074B3;
      border-bottom: 2px solid #0074B3;
      padding-bottom: 2px;
    }
  }
  .vehicle-model {
    > div {
      padding: 0 !important;
    }
    p.model {
      padding: 0 15px;
    }
    p.description {
      padding: 0 15px;
    }

  }
  .vehicle-model,
  .vehicle-type {
    > div {
      cursor: pointer;
    }

    .vehicle-type-item {
      padding-right: 3px;
      padding-left: 0px;

      @include respond-to("mobile") {
        padding: 10px;
        border-radius: 10px;
      }

      &:hover {
        p{
          color: #0074B3;
        }
      }
    }

    .home-grid-item {
      &:hover {
        p {
          color: #0074B3 !important;
        }
      }
    }


    img {
      display: inline;
      @include respond-to("mobile") {
        border-radius: 30px;
      }
    }
    @include respond-to(sm) {
      margin: 0;
    }
    @include respond-to(md) {
    }
    p.model {
      font-size: 12px;
      margin-top: 8px;

      @include respond-to(sm) {
        margin-top: 12px;
      }
    }
    p.description {
      font-size: 10px;
      margin-top: 2px;
      @include respond-to(sm) {
        font-size: $font-size-h6;
      }
    }
  }
  .vehicle-model {
    p.description {
      margin-bottom: 20px;
      @include respond-to(sm) {
        margin-bottom: 26px;
      }
    }


  }
  .vehicle-type {
    p.model {
      margin-bottom: 20px;
      @include respond-to(sm) {
        margin-bottom: 26px;
      }
    }
  }
  /* spacer */
  .bottom-spacer {
    margin-top: 8px;
    @include respond-to(sm) {
      margin-top: 34px;
    }
  }
  #recentlyViewed {
    /* Recently Viewed Vehicles*/
    .recently-viewed-header {
      hr {
        background: #898d8f;
        border-top: none !important;
        height: 2px;
        margin-bottom: 28px !important;
        margin-inline: auto;
        margin-top: 13px !important;
        width: 45px;

        @include respond-to(sm) {
          width: 82px;
          margin-top: 25px !important;
          margin-bottom: 35px !important;
        }
      }
    }

    .viewed-vehicles {
      @include respond-to(sm) {
        margin-left: -11px !important;
        margin-right: -11px !important;
      }
      > div.viewed-vehicle,
      div.item {
        border-radius: 12px;
        background-color: #fff;
        padding: 0 !important;
        margin: 0 3% 2.5% 3%;
        @include respond-to(sm) {
          width: 48%;
          margin: 0 1% 2% 1%;
        }
        @include respond-to(md) {
          width: 24%;
          margin: 0 0.5% 1% 0.5%;
        }
        .list-car-img {
          height: 100%;
          /*overflow: hidden;*/
          @include respond-to(sm) {
            height: 320px;
          }
          @include respond-to(md) {
            height: 230px;
          }
          img {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            width: 100%;
            @include respond-to(sm) {
              width: 100%;
            }
            @include respond-to(md) {
              width: auto;
            }
          }
        }
        p.model {
          font-size: 15px;
          margin-top: 28px;
          @include respond-to(sm) {
            font-size: $font-size-copy;
            margin-top: 36px;
          }
          @include respond-to(md) {
            font-size: $font-size-h4;
            margin-top: 60px;
          }
        }
        p.description {
          font-size: $font-size-m-copy;
          margin-top: 4px !important;
          @include respond-to(sm) {
            font-size: 14px;
          }
        }
        p.price {
          font-size: 35px;
          margin-top: 25px;
          @include respond-to(sm) {
            font-size: 36px;
            margin-top: 36px;
          }
          @include respond-to(md) {
            font-size: $font-size-h2;
          }
        }
        p.miles {
          color: #000;
          font-size: $font-size-m-copy;
          margin-top: 10px !important;
          margin-bottom: 26px;
          @include respond-to(sm) {
            font-size: 14px;
            margin-bottom: 30px;
          }
        }
        .btn-secondary {
          margin-bottom: 26px;
          background-color: #fff;
          color: #0074B3;
          @include respond-to(sm) {
            margin-bottom: 30px;
          }

          &:hover {
            background-color: #0074B3;
            color: #fff;
          }


        }
      }
      img.honda-true {
        max-height: 230px;
        width: auto !important;
        margin: 0 auto;
      }
      /*navigation dots*/
      .owl-nav {
        display: none;
        opacity: 0;
      }
      .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
      }
      .owl-dots {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
      }
      .owl-dots .owl-dot {
        display: inline-block;
        zoom: 1;
        *display: inline;
      }
      .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #000;
        opacity: 0.3;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }
      .owl-dots .owl-dot.active span,
      .owl-dots .owl-dot:hover span {
        background: #fff;
        opacity: 1;
      }
    }
    /* centered columns styles */
    .row-centered {
      text-align: center;
    }
    .col-centered {
      display: inline-block;
      float: none;
      text-align: center;
      margin-right: -4px;
    }
  } // End viewed vehicles
  .owl-carousel {
    width: inherit;
  }
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

/*Hide X on the zipgate*/

.geo-overlay .geo-background .geo-close {
  background-image: none;
  opacity: 0 !important;
  z-index: -1;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 90%;
  max-width: 1380px;
  overflow: hidden;

  .hsp-promo-banner {
    background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Desktop-Modal.jpg');
    background-size: cover;
    background-position: right;

    @include respond-to("desktopsm") {
      background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Tablet-Modal.png');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("tablet") {
      background: white no-repeat url('/images/program-benefits/Honda-Service-Pass-Promo-Banner-Tablet-Modal.png');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("mobile") {
      background-image: none;
      height: auto;
    }
  }

  .leasing-content {
    p, ul {
      font-size: 12px !important;
      max-width: 60%;
      @include respond-to("tablet") {
        font-size: 11px !important;
      }
      @include respond-to("mobile") {
        font-size: 13px !important;
        max-width: 100%;
      }
    }
  }

  .leasing-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    border-bottom: solid 1px #c4c4c4;
    height: 50px;
    margin-top: 15px;
    text-align: left;
    padding-left: 20px;
  }

  .leasing-promo-banner {
    background: white no-repeat url('/images/program-benefits/modal-honda-certified-leasing_desktop.jpg');
    background-size: cover;
    background-position: right;
    height: 330px;

    @include respond-to("desktopsm") {
      background: white no-repeat url('/images/program-benefits/modal-honda-certified-leasing_tablet.jpg');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("tablet") {
      background: white no-repeat url('/images/program-benefits/modal-honda-certified-leasing_tablet.jpg');
      background-size: cover;
      background-position: right;
    }

    @include respond-to("mobile") {
      background-image: none;
      background-color: black;
      color: white;
      height: auto;
    }

    .btn-leasing {
      margin-top: 0 !important;
      @include respond-to("mobile") {
        width: 60%;
        font-size: 18px;
        height: 40px;
      }

      @include respond-to("tablet") {
        font-size: 18px;
        height: 40px;
        margin-top: 25px !important;
      }
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    border-bottom: solid 1px #c4c4c4;
    height: 45px;
    margin-top: 15px;
    text-align: left;
    padding-left: 20px;
  }
  div {
    padding: 0;
  }
  p {
    font-size: 14px !important;
    line-height: 19px !important;
    padding: 20px !important;
    width: 40% !important;

    @include respond-to("tablet") {
      font-size: 12px !important;
      line-height: 16px !important;
      width: 30% !important;
      padding: 10px 20px !important;
    }

    @include respond-to("mobile") {
      width: 100% !important;
      text-align: left !important;
    }
  }
  p:first-child {
    @include respond-to("tablet") {
      width: 55% !important;
    }
  }
  ul {
    font-size: 14px !important;
    line-height: 19px !important;
    padding: 0 20px !important;
    text-align: left;

    @include respond-to("tablet") {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    li {
      list-style-type: disc;
      margin-left: 20px;
    }
  }
}

/* The Close Button */
.close {
  color: #000;
  float: right;
  font-size: 36px;
  font-weight: bold;
  opacity: 1;
  line-height: .5;
  margin-top: 20px;
  margin-right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* ada dropdown combo*/
  .optionfocus
{
  background-color: rgb(180, 180, 180);
}

.sr-only {
  visibility: hidden;
}

.ahm-hero-carousel {
  margin-left: -15px;
  margin-right: -15px;
}